import { versions } from '../versions';

export const environment = {
    production: true,
    name: 'production',
    app: {
        name: 'Vinbörsen',
        version: versions.version,
        startpageId: '15iBBO49GnoUH4lv2pf7wA'
    },
    firebase: {
        apiKey: 'AIzaSyBNe9GlUcjdUrHLEjjNBCWVpXV-Au9m1s4',
        authDomain: 'vinborsen.firebaseapp.com',
        databaseURL: 'https://vinborsen.firebaseio.com',
        projectId: 'vinborsen',
        storageBucket: 'vinborsen.appspot.com',
        messagingSenderId: '150944625403',
        appId: '1:150944625403:web:e7e7e7d61bf74e311e765e',
        measurementId: 'G-K53K27CBZR'
    },
    sanity: {
        projectId: 'h2gok5qi',
        dataset: 'vinborsen',
        useCdn: true,
        token: 'sk4hnJSa0GnJp39GsKcxRYszHw1cMvRSFqRzc2jriYr3u8btl9PMhvPt7tlA7ZLivU22WEtOTin3te4lGEtmXqiJqgBEiUnKiBOnpeq1zWLObUXnLfLOfZmjuW4DqDpD2o5K2GYszjePR3qhtZ0iHx7YgpnO3QSfjO9qkkvkfDdod8XO47vC'
    },
    api: {
        base: 'https://api.vinborsen.se/'
    },
    locations: [
        {
            name: 'Production',
            hostname: 'vinborsen.se',
            protocol: 'https',
            port: null,
            prefix: '',
            default: true
        },
        {
            name: 'Stage',
            hostname: 'stage.vinborsen.se',
            protocol: 'https',
            port: null,
            prefix: ''
        },
        {
            name: 'Localhost',
            hostname: 'localhost',
            protocol: 'http',
            port: 4200,
            prefix: ''
        }
    ],
    algolia: {
        appId: 'X661ZVH0L7',
        apiKey: '266c40faa8be71a3030c76a578a5b1b5',
        indices: {
            products: 'prod_SB-ACTIVE'
        },
        referer: 'https://vinborsen.se'
    },
    segment: {
        apiKey: 'gOfHt5I0vrQ4nOvOs5GBTFU1wsE33Ice'
    }
};
