export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '4.4.5',
    name: 'vinborsen',
    versionDate: '2024-07-11T14:49:12.580Z',
    gitCommitHash: 'g36a01f42',
    gitCommitDate: '2024-07-11T14:47:08.000Z',
    versionLong: '4.4.5-g36a01f42',
    gitTag: 'v4.4.5',
};
export default versions;
